import {
  faCertificate,
  faKey,
  faMicrochip,
  faTty,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import {
  AcquirerDto,
  CreateAcquirer,
  GetAcquirerRequest,
  UpdateAcquirerRequest,
} from "api";
import classnames from "classnames";
import getApiClient from "common/ApiClientFactory";
import { removeCollectionPrefix, to11 } from "common/utils";
import BackButton from "components/BackButton";
import CompanySelect from "components/CompanySelect";
import Hero from "components/Hero";
import { user } from "pages/user/user.module";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Certificates from "../certificates/Certificates";
import Communications from "../communications/Communications";
import AcquirerKeys from "../keys/AcquirerKeys";
import TabApplications from "./TabApplications";

const AcquirerDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  const { id } = useParams<any>();
  const [dto, setDto] = useState<AcquirerDto>();
  const [tab, setTab] = useState("applications");

  const { handleSubmit, reset, control } = useForm<AcquirerDto>();

  const history = useHistory();

  const onSubmit = async (data: AcquirerDto) => {
    let client = getApiClient();

    if (id !== "new") {
      let request = new UpdateAcquirerRequest();

      request.id = dto.id;
      request.acquirerId = data.acquirerId;
      request.description = data.description;
      request.remarks = data.remarks;
      request.companyId = data.company?.id;

      client
        .put(request)
        .then(() => {
          toast.success("Acquirer updated");
        })
        .catch(() => {
          toast.error("Error");
        });
    } else {
      let request = new CreateAcquirer();
      Object.assign(request, data);

      request.companyId = data.company?.id;

      client
        .post(request)
        .then((response) => {
          toast.success("Acquirer created");
          history.push(
            `/admin/acquirers/${removeCollectionPrefix(response.id)}`
          );
        })
        .catch(() => {
          toast.error("Error");
        });
    }
  };

  useEffect(() => {
    async function async() {
      const client = getApiClient();

      if (id !== "new") {
        let request = new GetAcquirerRequest();

        request.id = id;
        let dto = await client.get(request);

        reset(dto);
        setDto(dto);
      } else {
        let obj = new AcquirerDto();
        reset(obj);
        setDto(obj);
      }
    }

    async();
  }, [id, reset]);

  return (
    <>
      <Hero />

      <section className="section mb-dropdown m-4">
        {dto && (
          <Container className="mb-5">
            <h3>
              <BackButton />
              Acquirer{" "}
              <small className="text-primary">{to11(dto.acquirerId)}</small>
            </h3>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                    Details
                  </small>
                </div>
                {isAdmin && (
                  <Row>
                    <Col lg="6" sm="12">
                      <FormGroup>
                        <Controller
                          control={control}
                          name="acquirerId"
                          render={({ field }) => (
                            <Input
                              placeholder="Acquirer ID"
                              type="number"
                              {...field}
                              innerRef={field.ref}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Controller
                        control={control}
                        name="description"
                        render={({ field }) => (
                          <Input
                            placeholder="Description"
                            type="text"
                            rules={{ required: true }}
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {isAdmin && (
                  <>
                    <Row>
                      <Col lg="6" sm="12">
                        <FormGroup>
                          <Controller
                            control={control}
                            name="remarks"
                            render={({ field }) => (
                              <Input
                                placeholder="Remarks"
                                type="text"
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <small className="text-uppercase font-weight-bold">
                        Assigned Company
                      </small>
                    </div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Controller
                            control={control}
                            name="company"
                            render={({ field }) => <CompanySelect {...field} />}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="mb-4 mt-2">
                  <Col>
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            </>

            {id !== "new" && (
              <>
                <hr />
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: tab === "applications" })}
                      onClick={() => setTab("applications")}
                    >
                      <FontAwesomeIcon icon={faMicrochip} /> Applications
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: tab === "public-keys" })}
                      onClick={() => setTab("public-keys")}
                    >
                      <FontAwesomeIcon icon={faKey} /> Public Keys
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: tab === "certificates" })}
                      onClick={() => setTab("certificates")}
                    >
                      <FontAwesomeIcon icon={faCertificate} /> Root Certificates
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({
                        active: tab === "communications",
                      })}
                      onClick={() => setTab("communications")}
                    >
                      <FontAwesomeIcon icon={faTty} /> Communication
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={tab} className="pt-3">
                  <TabPane tabId="applications">
                    <TabApplications acquirerId={dto.id} />
                  </TabPane>
                  <TabPane tabId="public-keys">
                    <AcquirerKeys acquirerId={`acquirers/${id}`} />
                  </TabPane>
                  <TabPane tabId="certificates">
                    <Certificates
                      TargetId={`acquirers/${id}`}
                      AllowedInterfaces={["COI", "MI_REC", "MI_SUB", "SI_INIT"]}
                    />
                  </TabPane>
                  <TabPane tabId="communications">
                    <Communications
                      TargetId={`acquirers/${id}`}
                      AllowedInterfaces={["COI", "MI_REC", "MI_SUB", "SI_INIT"]}
                    />
                  </TabPane>
                </TabContent>
              </>
            )}
          </Container>
        )}
      </section>
    </>
  );
};

export default AcquirerDetail;
