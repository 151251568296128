import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();
  const location = useLocation();

  if (!location.key) {
    return null;
  }

  return (
    <a onClick={() => history.goBack()} className="text-primary mr-2">
      <i className="ni ni-bold-left"></i>
    </a>
  );
};

export default BackButton;
