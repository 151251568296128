import { CreateMerchantRange, GetConfig } from "api";
import classNames from "classnames";
import getApiClient from "common/ApiClientFactory";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";

type PropType = {
  serviceCenterId: string;
  onChange: () => void;
};

type FormData = {
  merchantRange: string;
  merchantName: string;
};

const RequestMerchantRange = (props: PropType) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const request = new GetConfig();

  const { data, isLoading } = useQuery([request], () => {
    return getApiClient().get(request);
  });

  if (isLoading) {
    return null;
  }

  const onSubmit = (formData: FormData) => {
    let client = getApiClient();

    let request = new CreateMerchantRange();
    request.merchantRangeId = formData.merchantRange;
    request.name = formData.merchantName;
    request.serviceCenterId = props.serviceCenterId;

    client
      .post(request)
      .then(() => {
        reset({ merchantName: "", merchantRange: "" });
        if (
          formData.merchantRange.indexOf("*") < 0 &&
          !data.merchantRangeForceRequest
        ) {
          toast.success("Merchant Range created");
        } else {
          toast.success("Merchant Range requested");
        }
        props.onChange();
      })
      .catch(() => {
        toast.error("Merchant Range could not be created");
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 mt-4">
          <small className="text-uppercase font-weight-bold">
            {data.merchantRangeForceRequest ? "Request" : "New"} Merchant Range
          </small>
        </div>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Controller
                name="merchantRange"
                control={control}
                rules={{
                  required: true,
                  maxLength: 8,
                  pattern: /^[A-Za-z0-9]+\**$/,
                }}
                render={({ field }) => (
                  <Input
                    invalid={!!errors.merchantRange}
                    placeholder="Merchant Range"
                    type="text"
                    maxLength={8}
                    className={classNames("uppercase", {
                      "is-invalid": errors.merchantRange,
                    })}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup
              className={classNames({ "has-danger": errors.merchantName })}
            >
              <Controller
                name="merchantName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    name="merchantName"
                    placeholder="Merchant Name"
                    type="text"
                    invalid={!!errors.merchantRange}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            Max 8 characters (upper case), wildcards supported at the end.
            Example: <code>SAMPLE1*, EX****, MYRANGE</code>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" color="success">
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default RequestMerchantRange;
