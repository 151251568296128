import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CreateKernelRequest,
  DeleteKernelRequest,
  GetKernelRequest,
  KernelDto,
  UpdateKernelRequest,
} from "api";
import classNames from "classnames";
import getApiClient from "common/ApiClientFactory";
import BackButton from "components/BackButton";
import CompanySelect from "components/CompanySelect";
import Hero from "components/Hero";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, FormGroup, Input, Row } from "reactstrap";
import confirm from "reactstrap-confirm";

const KernelDetail = () => {
  let { id } = useParams<any>();
  const [dto, setDto] = useState<KernelDto>();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<KernelDto>({
    defaultValues: dto,
  });
  const history = useHistory();

  const onSubmit = async (data: KernelDto) => {
    let client = getApiClient();

    if (id === "new") {
      let request = new CreateKernelRequest();
      request.description = data.description;
      request.kernelId = data.kernelId;

      if (data.company && data.company.id) {
        request.companyId = data.company.id;
      }

      await client
        .post(request)
        .then(() => {
          toast.success("Kernel created");
          history.push("/admin/kernels");
        })
        .catch(() => {
          toast.error("Error");
        });
    } else {
      let request = new UpdateKernelRequest();
      request.id = id;
      request.description = data.description;
      request.kernelId = data.kernelId;

      if (dto.company) {
        request.companyId = data.company.id;
      }

      await client
        .put(request)
        .then(() => {
          toast.success("Kernel saved");
        })
        .catch(() => {
          toast.error("Error");
        });
    }
  };

  useEffect(() => {
    if (id !== "new" && id) {
      fetchKernel(id, setDto, (k) => reset(k));
    }

    if (id === "new") {
      setDto(new KernelDto());
    }
  }, [id, reset]);

  const onDeleteClick = async () => {
    let confirmed = await confirm({
      title: "Confirmation",
      message: "Do you really want to delete this Kernel ID?",
      confirmText: "confirm",
    });

    if (confirmed) {
      let request = new DeleteKernelRequest();
      request.id = id;

      let client = getApiClient();
      await client.delete(request);
      toast.success("Kernel deleted");
      history.push("/admin/kernels");
    }
  };

  return (
    <>
      <Hero />
      <section className="section mb-dropdown m-4">
        {dto && (
          <Container className="mb-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    <BackButton />
                    Kernel{" "}
                    <small className="text-primary">{dto.kernelId}</small>
                  </h3>
                </div>
                <div>
                  {id !== "new" && (
                    <Button color="danger" onClick={onDeleteClick}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Details
                </small>
              </div>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Controller
                      name="kernelId"
                      control={control}
                      rules={{ required: true, pattern: /^[a-fA-F0-9]{6}$/ }}
                      render={({ field }) => (
                        <Input
                          placeholder="Kernel ID"
                          type="text"
                          defaultValue={dto.kernelId}
                          className={classNames("uppercase")}
                          maxLength={6}
                          {...field}
                          invalid={!!errors.kernelId}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => (
                        <Input
                          placeholder="Description"
                          type="text"
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Assigned Company
                </small>
              </div>
              <Row>
                <Col>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="company"
                      render={({ field }) => <CompanySelect {...field} />}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Button type="submit" color="primary">
                    {id === "new" ? "Create" : "Save"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Container>
        )}
      </section>
    </>
  );
};

async function fetchKernel(
  id: string,
  setDto: React.Dispatch<React.SetStateAction<KernelDto>>,
  callback: (k: KernelDto) => void
) {
  let client = getApiClient();
  let request = new GetKernelRequest();

  request.id = id;
  let dto = await client.get(request);

  if (callback) {
    callback(dto);
  }

  setDto(dto);
}

export default KernelDetail;
