import {
  faAngleRight,
  faMinus,
  faTrash,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import {
  AddContactPersonRequest,
  ContactPersonDto,
  DeleteContactPersonFromTargetRequest,
  GetContactPeopleFromTargetRequest,
  GetTerminalSupplierRequest,
  ServiceCenterDto,
  SetPrimaryContactPerson,
  TerminalSupplierDto,
  UpdateTerminalSupplierRequest,
} from "api";
import getApiClient from "common/ApiClientFactory";
import { removeCollectionPrefix, to11 } from "common/utils";
import Hero from "components/Hero";
import SearchContactPersonTable from "components/SearchContactPersonTable";
import ServiceCenterSelect from "components/ServiceCenterSelect";
import { user } from "pages/user/user.module";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";

const TerminalSupplierDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  let { id, serviceCenterId } = useParams<any>();
  const [dto, setDto] = useState<TerminalSupplierDto>();
  const [contactPeople, setContactPeople] = useState<ContactPersonDto[]>();

  const { handleSubmit, control, reset } = useForm<TerminalSupplierDto>({
    defaultValues: dto,
  });

  const history = useHistory();

  const onServiceCenterSelected = (selection: ServiceCenterDto) => {
    dto.serviceCenter = selection;

    setDto(dto);
  };

  const onSubmit = async (data: TerminalSupplierDto) => {
    let client = getApiClient();

    let request = new UpdateTerminalSupplierRequest();
    request.id = `TerminalSuppliers/${id}`;
    request.description = data.description;
    request.remarks = data.remarks;

    if (dto.serviceCenter) {
      request.serviceCenterId = dto.serviceCenter.id;
    }

    request.terminalSupplierId = data.terminalSupplierId;

    client
      .put(request)
      .then(() => {
        toast.success("Terminal Supplier saved");
      })
      .catch(() => {
        toast.error("Error");
      });
  };

  useEffect(() => {
    if (id !== "new" && id) {
      fetchTerminalSupplier(id, setDto);
      fetchContactPeople(id, setContactPeople);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const addContactPerson = (targetId: string, contactPersonId: string) => {
    let client = getApiClient();

    let request = new AddContactPersonRequest();
    request.id = targetId;
    request.contactPersonId = contactPersonId;

    client.post(request).then(() => {
      fetchContactPeople(id, setContactPeople);
      toast.success("contact person added");
    });
  };

  const deleteContactPerson = (targetId: string, contactPersonId: string) => {
    let client = getApiClient();

    let request = new DeleteContactPersonFromTargetRequest();
    request.id = targetId;
    request.contactPersonId = contactPersonId;

    client.delete(request).then(() => {
      toast.success("contact person removed");
      fetchTerminalSupplier(id, setDto);
      fetchContactPeople(id, setContactPeople);
    });
  };

  const linkToDetail = (contactPersonId: string) => {
    return `${
      isAdmin ? "/admin" : ""
    }/terminal-suppliers/${id}/contact-people/${removeCollectionPrefix(
      contactPersonId
    )}`;
  };

  const goToNewContactPerson = () => {
    if (isAdmin) {
      history.push(`/admin/terminal-suppliers/${id}/contact-people/new`);
    } else {
      history.push(`/terminal-suppliers/${id}/contact-people/new`);
    }
  };

  const setPrimary = (contactPersonId: string) => {
    let request = new SetPrimaryContactPerson();
    request.contactPersonId = contactPersonId;
    request.targetId = dto.id;

    let client = getApiClient();
    client.put(request).then(() => {
      dto.primaryContactPersonId = contactPersonId;
      fetchContactPeople(id, setContactPeople);
      toast.success("Primary contact person updated");
    });
  };

  async function fetchTerminalSupplier(
    id: string,
    setDto: React.Dispatch<React.SetStateAction<TerminalSupplierDto>>
  ) {
    let client = getApiClient();
    let request = new GetTerminalSupplierRequest();

    request.id = id;
    let dto = await client.get(request);

    setDto(dto);
    reset(dto);
  }

  async function fetchContactPeople(
    id: string,
    setContactPeople: React.Dispatch<React.SetStateAction<ContactPersonDto[]>>
  ) {
    let client = getApiClient();
    let request = new GetContactPeopleFromTargetRequest();

    request.targetId = `TerminalSuppliers/${id}`;

    let response = await client.get(request);
    setContactPeople(response);
  }

  const buttonText = id === "new" ? "add" : "save";

  const backLink = () => {
    if (serviceCenterId) {
      return `/service-centers/${serviceCenterId}`;
    }

    return `/search`;
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        {dto && (
          <Container className="mb-5">
            <h3>
              <Link to={backLink()}>
                <i className="ni ni-bold-left"></i>
              </Link>
              Terminal Supplier{" "}
              <small className="text-primary">
                {to11(dto.terminalSupplierId)}
              </small>
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Details
                </small>
              </div>
              {isAdmin && (
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Controller
                        control={control}
                        name="terminalSupplierId"
                        render={({ field }) => (
                          <Input
                            placeholder="Terminal Supplier ID"
                            type="number"
                            {...field}
                            innerRef={field.ref}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => (
                        <Input
                          placeholder="Description"
                          type="text"
                          {...field}
                          innerRef={field.ref}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isAdmin && (
                <>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Controller
                          control={control}
                          name="remarks"
                          render={({ field }) => (
                            <Input
                              placeholder="Remarks"
                              type="text"
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <ServiceCenterSelect
                          defaultValue={dto.serviceCenter}
                          onChange={(selection) =>
                            onServiceCenterSelected(selection)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="mt-2">
                <Col>
                  <Button type="submit" color="primary">
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
            <div className="mb-3 mt-4">
              <small className="text-uppercase font-weight-bold">
                Contact people
              </small>
            </div>
            <Row>
              <Col>
                <Table className="align-items-center table-sm">
                  <thead>
                    <tr>
                      <th scope="col">First name</th>
                      <th scope="col">Last name</th>
                      <th scope="col">E-Mail</th>
                      <th scope="col">Phone</th>
                      <th scope="col">&nbsp;</th>
                      {isAdmin && <th scope="col">User</th>}
                      <th scope="col">&nbsp;</th>
                      {isAdmin && <th scope="col">&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {contactPeople?.map((contactPerson) => {
                      return (
                        <tr key={contactPerson.id}>
                          <td>{contactPerson.firstName}</td>
                          <td>{contactPerson.lastName}</td>
                          <td>{contactPerson.email}</td>
                          <td>{contactPerson.phone}</td>
                          <td>
                            {contactPerson.id === dto.primaryContactPersonId ? (
                              <Badge color="info">primary</Badge>
                            ) : (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => setPrimary(contactPerson.id)}
                              >
                                set primary
                              </Button>
                            )}
                          </td>
                          {isAdmin && (
                            <td>
                              {contactPerson.userId ? (
                                <FontAwesomeIcon icon={faUserCheck} />
                              ) : (
                                <FontAwesomeIcon icon={faMinus} />
                              )}
                            </td>
                          )}
                          <td>
                            {!isAdmin && contactPeople.length === 1 ? (
                              ""
                            ) : (
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() =>
                                  deleteContactPerson(
                                    `TerminalSuppliers/${id}`,
                                    contactPerson.id
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            )}
                          </td>
                          {isAdmin && (
                            <td>
                              <Link to={linkToDetail(contactPerson.id)}>
                                <FontAwesomeIcon icon={faAngleRight} />
                              </Link>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {isAdmin && (
              <Row>
                <Col>
                  <SearchContactPersonTable
                    action={(contactPerson) => {
                      addContactPerson(
                        `TerminalSuppliers/${id}`,
                        contactPerson.id
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button
                  color="success"
                  size="sm"
                  onClick={() => goToNewContactPerson()}
                >
                  New Contact Person
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

export default TerminalSupplierDetail;
