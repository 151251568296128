import {
  faAngleRight,
  faMinus,
  faTrash,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimplux } from "@simplux/react";
import {
  AddContactPersonRequest,
  CompanyDto,
  ContactPersonDto,
  CreateCompanyRequest,
  DeleteContactPersonFromTargetRequest,
  GetCompanyRequest,
  GetContactPeopleFromTargetRequest,
  ICompanyInfos,
  SetPrimaryContactPerson,
  UpdateCompanyRequest,
} from "api";
import getApiClient from "common/ApiClientFactory";
import { removeCollectionPrefix } from "common/utils";
import BackButton from "components/BackButton";
import Hero from "components/Hero";
import SearchContactPersonTable from "components/SearchContactPersonTable";
import { user } from "pages/user/user.module";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import CompanyMerger from "./CompanyMerger";

const CompanyDetail = () => {
  const isAdmin = useSimplux(user.isAdmin);

  let { id } = useParams<any>();
  const [dto, setDto] = useState<CompanyDto>();
  const [contactPeople, setContactPeople] = useState<ContactPersonDto[]>();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ICompanyInfos>({
    defaultValues: dto,
  });

  const history = useHistory();

  const onSubmit = async (data: ICompanyInfos) => {
    let client = getApiClient();

    if (id === "new") {
      let request = new CreateCompanyRequest();
      Object.assign(request, data);

      let response = await client.post(request);

      toast.success("Company created");

      history.push(`/admin/companies/${removeCollectionPrefix(response.id)}`);
    } else {
      let request = new UpdateCompanyRequest();
      Object.assign(request, data);
      request.id = `companies/${id}`;

      await client.put(request);

      toast.success("Company saved");
    }
  };

  useEffect(() => {
    if (id !== "new" && id) {
      fetchCompanyAsync(id, setDto, (c) => reset(c));
      fetchContactPeople(id, setContactPeople);
    } else {
      let o = new CompanyDto();
      reset(o);
      setDto(o);
    }
  }, [id, isAdmin, reset]);

  const addCompanyPerson = (companyId: string, contactPersonId: string) => {
    let client = getApiClient();

    let request = new AddContactPersonRequest();
    request.id = companyId;
    request.contactPersonId = contactPersonId;

    client.post(request).then(() => {
      fetchContactPeople(id, setContactPeople);
      toast.success("contact person added");
    });
  };

  const deleteCompanyContactPerson = (
    targetId: string,
    contactPersonId: string
  ) => {
    let client = getApiClient();

    let request = new DeleteContactPersonFromTargetRequest();
    request.id = targetId;
    request.contactPersonId = contactPersonId;

    client.delete(request).then(() => {
      toast.success("contact person removed");
      fetchCompanyAsync(id, setDto);
      fetchContactPeople(id, setContactPeople);
    });
  };

  const linkToDetail = (contactPersonId: string) => {
    return `${
      isAdmin ? "/admin" : ""
    }/companies/${id}/contact-people/${removeCollectionPrefix(
      contactPersonId
    )}`;
  };

  const buttonText = id === "new" ? "add" : "save";

  const goToNewContactPerson = () => {
    if (isAdmin) {
      history.push(`/admin/${dto.id}/contact-people/new`);
    } else {
      history.push(`/${dto.id}/contact-people/new`);
    }
  };

  const setPrimary = (contactPerson: ContactPersonDto) => {
    let request = new SetPrimaryContactPerson();
    request.contactPersonId = contactPerson.id;
    request.targetId = dto.id;

    let client = getApiClient();
    client.put(request).then(() => {
      dto.primaryContactPerson = contactPerson;
      fetchContactPeople(id, setContactPeople);
    });
  };

  return (
    <>
      <Hero />
      <section className="section m-4">
        {dto && (
          <Container className="mb-5">
            <h3>
              <BackButton />
              Company
            </h3>
            <div className="mb-3">
              <small className="text-uppercase font-weight-bold">Details</small>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg="6" sm="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="name"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Name"
                          type="text"
                          {...field}
                          invalid={!!errors.name}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" sm="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="street1"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Address 1"
                          type="text"
                          {...field}
                          invalid={!!errors.street1}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" sm="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="street2"
                      render={({ field }) => (
                        <Input
                          placeholder="Address 2"
                          type="text"
                          {...field}
                          invalid={!!errors.street2}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2" sm="4">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="postCode"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Post Code"
                          type="text"
                          {...field}
                          invalid={!!errors.postCode}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" sm="8">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="City"
                          type="text"
                          {...field}
                          invalid={!!errors.city}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" sm="12">
                  <FormGroup>
                    <Controller
                      control={control}
                      name="country"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Country"
                          type="text"
                          {...field}
                          invalid={!!errors.country}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isAdmin && (
                <Row>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Input type="select" name="type" {...field}>
                            <option>Subscriber</option>
                            <option>Supplier</option>
                            <option>Acquirer</option>
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col>
                  <Button type="submit" color="primary">
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
            {id !== "new" && (
              <>
                <div className="mb-3 mt-4">
                  <small className="text-uppercase font-weight-bold">
                    Contact people
                  </small>
                </div>
                <Row>
                  <Col>
                    <Table className="align-items-center table-sm">
                      <thead>
                        <tr>
                          <th scope="col">First name</th>
                          <th scope="col">Last name</th>
                          <th scope="col">E-Mail</th>
                          <th scope="col">Phone</th>
                          <th scope="col">&nbsp;</th>
                          {isAdmin && <th scope="col">User</th>}
                          <th scope="col">&nbsp;</th>
                          {isAdmin && <th scope="col">&nbsp;</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {contactPeople?.map((contactPerson) => {
                          return (
                            <tr key={`${contactPerson.id}`}>
                              <td>{contactPerson.firstName}</td>
                              <td>{contactPerson.lastName}</td>
                              <td>{contactPerson.email}</td>
                              <td>{contactPerson.phone}</td>
                              <td>
                                {contactPerson.id ===
                                dto.primaryContactPerson?.id ? (
                                  <Badge color="info">primary</Badge>
                                ) : (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => setPrimary(contactPerson)}
                                  >
                                    set primary
                                  </Button>
                                )}
                              </td>
                              {isAdmin && (
                                <td>
                                  {contactPerson.userId ? (
                                    <FontAwesomeIcon icon={faUserCheck} />
                                  ) : (
                                    <FontAwesomeIcon icon={faMinus} />
                                  )}
                                </td>
                              )}
                              <td>
                                {!isAdmin && contactPeople.length === 1 ? (
                                  ""
                                ) : (
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() =>
                                      deleteCompanyContactPerson(
                                        `companies/${id}`,
                                        contactPerson.id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                )}
                              </td>
                              {isAdmin && (
                                <td>
                                  <Link to={linkToDetail(contactPerson.id)}>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                  </Link>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>
            )}
            {isAdmin && id !== "new" && (
              <>
                <Row>
                  <Col>
                    <SearchContactPersonTable
                      action={(contactPerson) => {
                        addCompanyPerson(`companies/${id}`, contactPerson.id);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CompanyMerger targetCompanyId={`companies/${id}`} />
                  </Col>
                </Row>
              </>
            )}
            {id !== "new" && (
              <Row>
                <Col>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => goToNewContactPerson()}
                  >
                    New Contact Person
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        )}
      </section>
    </>
  );
};

async function fetchCompanyAsync(
  id: string,
  setDto: React.Dispatch<React.SetStateAction<CompanyDto>>,
  callback?: (dto: CompanyDto) => void
) {
  let client = getApiClient();
  let request = new GetCompanyRequest();

  request.id = `companies/${id}`;
  let dto = await client.get(request);

  if (callback) {
    callback(dto);
  }

  setDto(dto);
}

async function fetchContactPeople(
  id: string,
  setContactPeople: React.Dispatch<React.SetStateAction<ContactPersonDto[]>>
) {
  let client = getApiClient();
  let request = new GetContactPeopleFromTargetRequest();

  request.targetId = `companies/${id}`;

  let response = await client.get(request);
  setContactPeople(response);
}

export default CompanyDetail;
